* {
  box-sizing: border-box;;
}

.row {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.tablet-show {
  display: none;
}

@media (min-width: 801px) {
  .row {
    max-width: 697px;
  }

  .tablet-show {
    display: block;
  }

  .only-mobile {
    display: none;
  }
}

@media (min-width: 1064px) {
  .row {
    max-width: 1058px;
  }
}

input[type="submit"] {
  -webkit-appearance: none;
}

input:not(:disabled) { cursor: pointer;}
