.results-wrapper {
    padding: 32px 30px 0;
    min-height: 100vh;
    width: 100%;
    background-color: #171717;
    background-size: 525px 505px;
    background-repeat: no-repeat;
    background-position: center -105px;
}

.results-wrapper.ninja {
    background-image: url('./ninja.png');
}

.results-wrapper.tenx {
    background-image: url('./10x.png');
}

.results-wrapper.copy {
    background-image: url('./copy.png');
}

.results-wrapper .logo {
    margin-bottom: 17px;
}

.results-wrapper .title {
    color: white;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 102px;
}

.results-wrapper .dev-title.tenx {
    background: -webkit-linear-gradient(90.23deg, #FFA740 1.93%, #FFE8D2 101.76%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.results-wrapper .dev-title.ninja {
    background: -webkit-linear-gradient(90.23deg, #68A4FF 1.93%, #D2F7FF 101.76%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.results-wrapper .dev-title.copy {
    background: -webkit-linear-gradient(90.23deg, #ED7DFF 1.93%, #F1D2FF 101.76%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.results-wrapper .dev-title {
    font-size: 32px;
    line-height: 42px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 32px;

}

.results-wrapper .submit {
    margin: 80px auto;
    display: block;
    max-width: 200px;
    background-color: #ffda2a;
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;;
    padding: 13px 0;
    border-radius: 30px;
    color: #171717;
    text-align: center;
    text-decoration: none;
}

.results-wrapper video {
    max-width: 100%;
    margin-bottom: 50px;
}


@media (min-width: 801px) {
}

@media (min-width: 1064px) {
    /* .results-wrapper .main > div {
        display: flex;
    }
    .results-wrapper .main > div > * {
        width: 50%;
    } */
}