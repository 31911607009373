.choices-wrapper {
    min-height: 100vh;
    width: 100%;
    background-color: #171717;
    padding: 32px 15px 50px;
}

.choices-wrapper .form-wrapper {
    padding: 0 15px;
}

.choices-wrapper .title {
    font-size: 32px;
    line-height: 42px;
    font-weight: 700;
    text-align: center;
    background: -webkit-linear-gradient(left, #957DFF, #C0B4FF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 12px;
}

.choices-wrapper .choice {
    padding-bottom: 10px;
}

.choices-wrapper .option-title {
    font-size: 18px;
    line-height: 23px;
    font-weight: 700;
    color: white;
    padding: 20px 0;
}

.choices-wrapper .choice button {
    border: 1px solid #808080;
    border-radius: 14px;
    color: #ccc;
    width: 100%;
    padding: 14px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 21px;
    text-align: left;
    background: linear-gradient(87.38deg, rgba(255, 255, 255, 0.2) 1.74%, rgba(255, 255, 255, 0) 100%);
    cursor: pointer;
}

.choices-wrapper .choice button.active {
    border-color: #FFDA2A;
    background: linear-gradient(87.12deg, rgba(255, 218, 42, 0.4) 0%, rgba(255, 218, 42, 0) 100%);
}

.choices-wrapper .submit {
    margin: 20px 0 50px;
}

.choices-wrapper .submit:disabled {
    background-color: gray;
}


@media (min-width: 801px) {
  
}

@media (min-width: 1064px) {
    .choices-wrapper .choice {
        padding: 0 40px 40px;
    }
    .choices-wrapper .option-title {
        text-align: center;
    }
    .choices-wrapper .choice button {
        width: calc(50% - 14px); 
        margin: 7px;
    }

    .choices-wrapper .blur {
        position: absolute;
        width: 421px;
        height: 276px;        
        background: #5C37FF;
        filter: blur(140px);
    }
    .choices-wrapper .blur.top {
        left: -176px;
        top: -176px;
    }
    .choices-wrapper .blur.bottom {
        left: calc(100% - 267px);
        top: calc(100vh + 270px);
    }
}