.home-wrapper {
    min-height: 100vh;
    width: 100%;
    background-color: #171717;
}

.home-wrapper .head {
    background-image: url('./head.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: calc(100vw * 1.95);
    max-height: 80vh;
    padding: 35px 20px;
    box-sizing: border-box;
}

.logo {
    display: block;
    width: 60px;
    margin: 0 auto 20px;
}

.home-wrapper .text {
    color: white;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
}

.home-wrapper .form-wrapper {
    padding: 36px 30px 0;
    position: relative;
    overflow: hidden;
}

.home-wrapper .blur {
    position: absolute;
    width: 421px;
    height: 276px;
    left: -267px;
    top: -176px;
    
    background: #5C37FF;
    filter: blur(140px);
}

.home-wrapper .form-inner-wrapper {
    transform: translateZ(10px);
}

.home-wrapper .form-title {
    color: white;
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 12px;
}

.home-wrapper .form-subtitle {
    color: #B3B3B3;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 20px;
}

.home-wrapper .form input:not(.submit) {
    border: 1px solid #B3B3B3;
    color: #b3b3b3;
    border-radius: 8px;
    width: 100%;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 20px;
    background-color: transparent;
    padding: 15px;
    box-sizing: border-box;
    outline: none;
    transition: all 0.2s ease;
}

.home-wrapper .form input:not(.submit):focus {
    border-color: #ffda2a;
}

.home-wrapper .terms {
    color: #B3B3B3;
    font-size: 12px;
    line-height: 26px;
    margin-bottom: 10px;
}

input.submit {
    background-color: #ffda2a;
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;;
    padding: 13px 0;
    border: none;
    border-radius: 30px;
    color: #171717;
}

.home-wrapper .bottom {
    background: linear-gradient(181.34deg, #181818 10.35%, #240DE6 42.96%, #1185F3 77.75%, #00F0FF 99.28%);
    padding-top: 100px;
    padding-bottom: 70px;
}

.home-wrapper .trusted-title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    color: white;
    text-align: center;
}

.home-wrapper .trusted-img {
    display: block;
    margin: 0 auto 30px;
}

.home-wrapper .albums {
    display: block;
    margin: 0 auto;
}

@media (min-width: 801px) {
    .home-wrapper { 
        background-image: url('./head_tablet.png');
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 100% 557px;
    }
    .home-wrapper .head { 
        background: none;
        max-height: 557px;
    }
    .home-wrapper .text {
        width: 50%;
        text-align: left;
    }
    .logo {
        margin: 0 0 150px;
    }
    .home-wrapper .form-inner-wrapper {
        text-align: center;
    }
    .home-wrapper .form-title {
        color: #957DFF;
    }
    .home-wrapper .form-wrapper .form input {
        width: 40%;
        margin: 20px;
    }
    .home-wrapper .bottom.row {
        background: none;
    }

    .home-wrapper .blur.left {
        left: -267px;
        top: 676px;
    }
    .home-wrapper .blur.bottom {
        left: 90%;
        top: 1200px;
    }
}

@media (min-width: 1064px) {
    .home-wrapper .text {
        width: 40%;
        font-size: 36px;
        line-height: 47px;
    }
}