body {
  margin: 0;
  font-family: 'Artlist Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0;
}


@font-face {
  font-family: 'Artlist Sans';
  src: url(./fonts/ArtlistSans-Light.woff2) format('woff2'),
      url(./fonts/ArtlistSans-Light.woff) format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
 
@font-face {
  font-family: 'Artlist Sans';
  src: url(./fonts/ArtlistSans-LightItalic.woff2) format('woff2'),
      url(./fonts/ArtlistSans-LightItalic.woff) format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Artlist Sans';
  src: url(./fonts/ArtlistSans-Regular.woff2) format('woff2'),
      url(./fonts/ArtlistSans-Regular.woff) format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Artlist Sans';
  src: url(./fonts/ArtlistSans-Medium.woff2) format('woff2'),
      url(./fonts/ArtlistSans-Medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Artlist Sans';
  src: url(./fonts/ArtlistSans-Bold.woff2) format('woff2'),
      url(./fonts/ArtlistSans-Bold.woff) format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
